import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const brandSecondary = definePartsStyle({
  container: {
    bgColor: 'secondaryPurple.200',
    color: 'secondaryPurple.600',
    borderRadius: '11px',
  },
  label: {
    color: 'secondaryPurle.600',
    fontWeight: '700',
    lineHeight: '150%',
  },
});

const mdContainer = defineStyle({
  py: '10px',
  px: '30px',
});
const mdLabel = defineStyle({
  fontSize: '22px',
  letterSpacing: '0.44px',
});

const smContainer = defineStyle({
  py: '6px',
  px: '15px',
});
const smLabel = defineStyle({
  fontSize: '12px',
  letterSpacing: '0.24px',
});

const sizes = {
  md: definePartsStyle({ container: mdContainer, label: mdLabel }),
  sm: definePartsStyle({ container: smContainer, label: smLabel }),
};

export const tagTheme = defineMultiStyleConfig({
  sizes,
  variants: {
    brandSecondary,
  },
});
